import React from 'react'
import { graphql } from 'gatsby'
import Article, { VARIANT_OPENER, VARIANT_TEXT_INTENDED } from '../modules/Article'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'

const Page = ({ data }) => {
  const postNode = {
    title: `Möbelmanufaktur - ${config.siteTitle}`,
  }

  const block1 = {
    leading: 5,
    laces: true,
    variant: VARIANT_OPENER,
    headline: `Möbelmanufaktur`,
    lead: `Sie suchen schon lange nach dem passenden Sofa oder haben eine bestimmte Vorstellung von einem Sitzmöbel? Sie benötigen ein gleiches, ergänzendes Polstermöbel in Ihrer Sitzgruppe?`,
    image: {
      alt: 'Placeholder',
      fluid: data.opener.childImageSharp.fluid
    },
  }
  
  const block2 = {
    dropcap: true,
    variant: VARIANT_TEXT_INTENDED,
    copytext: `<p>Dann greifen Sie auf unsere Manufaktur zurück! Wir haben langjährige Erfahrung, um genau diese Wünsche und Anforderungen zu realisieren. Wir zeigen Ihnen gerne von uns bereits durchgeführte Projekte und überzeugen Sie von den Möglichkeiten. Ob für Privat oder Objekt, ob mit Sichtholzgestell oder traditionell geschnürtem Sitz, das Möbel nach ihrem Wunsch setzen wir für Sie um.</p>`,
  }
  
  return (
    <Layout>
      <Helmet>
        <title>{`Möbelmanufaktur - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="moebelmanufaktur" customTitle />

      <div>
        <article>
          <Article {...block1}></Article>
          <Article {...block2}></Article>
        </article>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    opener: file(relativePath: { eq: "moebelmanufaktur-opener.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
